<template>
    <div class="py-28 pb-20 text-white hero">
        <div
            class="flex flex-col lg:flex-row justify-between items-center gap-8 max-w-7xl mx-auto px-4 pb-20 lg:text-left text-center">
            <div class="text-pretty">
                <h1 class="font-bold lg:text-4xl text-3xl font-normal">Document Answering
                </h1>
                <div class="mt-4 text-lg">
                    <p>High-accuracy Document Answering at scale using the most up-to-date knowledge available in your
                        data sources
                    </p>
                </div>
                <div class="flex lg:flex-row flex-col gap-4 items-start lg:items-center items-center mt-8 lg:mt-8">
                    <Calendly name="requestdemo" href="https://calendly.com/d/dj2-4jd-3tw/introductory-call-pathway"
                        class="flex gap-2 items-center font-bold text-xl hover:bg-transparent glow-btn block max-w-max bg-[#dc280b] px-6 py-3 rounded-lg border-2 border-[#dc280b] !transition">
                        <Icon name="heroicons:check-circle-20-solid" /> Let's Talk
                    </Calendly>
                    <a href="/developers/templates/rag/demo-question-answering"
                        class="flex gap-2 items-center font-bold text-xl bg-transparent block max-w-max hover:bg-[#dc280b] px-6 py-3 rounded-lg border-2 border-[#dc280b] !transition">
                        Try it out
                    </a>
                </div>

            </div>
            <div>
                <div>
                    <img src="/assets/content/solutions/PDF-to-SQL-Table.gif" alt="" />
                    <!-- <video width="640" height="480" controls
                        class="relative w-full max-w-[30rem] md:min-w-[30rem] min-w-full shadow-[5px_5px_0px_0px_rgba(24,24,64)]">
                        <source src="/assets/content/solutions/PDF-to-SQL-Table.gif" type="video/mp4">
                        Your browser does not support the video tag.
                    </video> -->
                </div>
            </div>
        </div>
    </div>
    <hr />
    <div class=" bg-white">
        <div class="max-w-7xl mx-auto px-4 py-8">
            <slot />
        </div>
        <div class="max-w-7xl mx-auto md:p-8 px-4 py-8 text-[#232a34] md:pb-24">
            <div class="flex gap-4 justify-center items-center">
                <Calendly name="requestdemo" href="https://calendly.com/d/dj2-4jd-3tw/introductory-call-pathway"
                    class="flex gap-2 items-center text-white font-bold text-xl hover:bg-transparent glow hover:text-secondary-500 block max-w-max bg-[#dc280b] px-6 py-3 rounded-lg border-2 border-[#dc280b] !transition">
                    <Icon name="heroicons:check-circle-20-solid" /> Let's Talk
                </Calendly>
                <a href="/developers/templates/rag/demo-question-answering"
                    class="flex gap-2 items-center font-bold text-xl hover:text-white bg-transparent block max-w-max hover:bg-[#dc280b] px-6 py-3 rounded-lg border-2 border-[#dc280b] !transition">
                    Try it out
                </a>
            </div>

        </div>
    </div>

</template>

<style scoped>
.hero {
    background: #141d2d;
    background: linear-gradient(90deg, #141d2d 42%, #152033 50%, #2729b0);
}

.glow-btn {
    box-shadow: 0 5px 15px rgba(243, 23, 23, 0.4);
}

td {
    padding: 0.5rem;
    border: 1px solid #F7F6FE;
    transition: background-color 0.4s;
}

tr:hover {
    background-color: #F8F6FF;
    transition: background-color 0.4s;
}

tr:last-of-type:hover {
    background-color: white;
}

table {
    border: 1px solid #F7F6FE;
    padding: 1rem;
}

.quote-slider {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: -1rem;
        left: 50%;
        transform: translateX(-50%);
        width: 2rem;
        height: 2rem;
        padding: 1rem;
        background-color: #fff;
        background-image: url("/assets/landing/quote.svg");
        color: red;
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid #7B8088;
        border-radius: 50%;
    }
}
</style>
