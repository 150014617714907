<template>
    <div class="py-20 pb-0 text-white hero ">
        <div class="flex flex-col lg:flex-row justify-between items-center gap-4 max-w-7xl mx-auto px-4">
            <div class="pb-20 text-pretty">
                <h1 class="font-bold md:text-3xl text-2xl pt-24">ETL for Unstructured Data</h1>
                <div class="mt-8 text-lg">
                    <ul class="list-disc pl-6">
                        <li>Powerful data Transformation for all your ML use cases.</li>
                        <li>Get instant insights from streams of unstructured data on-the-fly.</li>
                        <li>Connect live sources with documents, e-mails, social media and messages, audio and video
                            transcripts, geospatial data, and more.</li>
                    </ul>
                    <p class="mt-8">Only Pathway handles streaming data joins and contextual data analysis at scale.</p>
                </div>
                <div class="flex md:flex-row flex-col gap-4 items-start mt-8 lg:mt-0">
                    <a href="/developers/user-guide/introduction/welcome"
                        class="flex gap-2 items-center font-bold text-xl mt-8 hover:bg-transparent block max-w-max bg-[#dc280b] px-6 py-3 rounded-lg border-2 border-[#dc280b] !transition">
                        <Icon name="heroicons:code-bracket-16-solid" /> Get started
                    </a>

                    <NuxtLink to="/developers/templates?q=unstructured"
                        class="flex gap-2 items-center font-bold text-xl mt-8 bg-transparent block max-w-max hover:bg-[#dc280b] px-6 py-3 rounded-lg border-2 border-[#dc280b] !transition">
                        See the tutorials</NuxtLink>
                </div>
            </div>
            <div class="md:w-[80%]">
                <span class="font-[500] text-2xl">Reference architecture</span>
                <ZoomImage src="/assets/solutions/Deployment-architecture-Pathway.svg"
                    alt="Deployment architecture of Pathway" />
            </div>
        </div>
    </div>
    <hr class="border-[#242424]" />
    <div class="bg-[#1F1F1F]">
        <div class="p-4 max-w-7xl mx-auto px-4 py-16">
            <div class="p-8 border-[#7B8088] border rounded-xl shadow-[0_3px_10px_rgb(43,47,168,0.82)] quote-slider">
                <div class="lg:p-8 p-2 text-white">
                    <p class="text-center md:text-xl text-lg font-light">All other Unstructured ETL tools out there
                        provide
                        connectors. Pathway provides data sync, data transformation, and data indexing, at scale. Plus,
                        the
                        same
                        connectors, or better.</p>
                    <span class="text-center block mt-8 text-xl">Enterprise Architect @ Financial Document Processing
                        scale-up</span>
                </div>
            </div>
        </div>
    </div>
    <div class=" bg-[#F8FAFC] pb-14">
        <div class="max-w-7xl mx-auto md:p-14 px-4 py-14 text-[#232a34]">
            <h2 id="pricing" class="text-4xl font-semibold text-center">See it in action</h2>
            <div class="mt-16 flex flex-col gap-16">
                <div class="flex flex-col lg:flex-row gap-8 justify-between items-center text-center lg:text-left">
                    <div class="lg:w-1/2">
                        <NuxtLink to="/developers/templates/rag/unstructured-to-structured"
                            class="text-2xl pw-text-black font-normal">AI document preparation on-the-fly</NuxtLink>
                        <p class="mt-2 mb-6 text-pretty"> Convert unstructured financial documents into SQL tables.
                            Pathway's
                            <NuxtLink to="/developers/user-guide/llm-xpack/overview/#preparing-documents-for-llms"
                                class="text-primary-500 underline inline">
                                Unstructured Xtension Pack</NuxtLink> allows you to choose the best suited connectors
                            for your document use case. Use unstructured-io connectors directly, extract JSON from scans
                            and images with Vision-Language Models, or create custom Python OCR.
                        </p>
                        <NuxtLink to="/developers/templates/rag/unstructured-to-structured"
                            class="inline items-center font-normal hover:underline text-primary-500 transition">
                            Check It
                            Out
                            <Icon name="heroicons:arrow-right-16-solid"
                                class="w-4 h-4 inline align-middle mb-1 ml-1 button-arrow" />
                        </NuxtLink>
                    </div>
                    <div>
                        <div
                            class="lg:w-[30rem] w-full h-48 bg-[#F8FAFC] rounded-lg flex items-center justify-center font-bold text-gray-200">
                            <NuxtLink to="/developers/templates/rag/unstructured-to-structured"
                                class="object-cover w-full h-full">
                                <NuxtImg src="/assets/content/showcases/llm-app/unstructured_to_sql_demo.gif"
                                    alt="Highlighted changes in doc" class="rounded-lg object-cover w-full h-full" />
                            </NuxtLink>
                        </div>
                    </div>
                </div>
                <div class="flex flex-col lg:flex-row gap-8 justify-between items-center text-center lg:text-left">
                    <div class="lg:w-1/2">
                        <NuxtLink to="/developers/templates/etl/twitter" class="text-2xl pw-text-black font-normal">
                            Intelligent news stream sentiment analysis</NuxtLink>
                        <p class="mt-2 mb-6 text-pretty">See how Pathway can be used to process a real-time stream of
                            social
                            network data with NLP to intelligently improve geolocation, and perform predictive sentiment
                            analysis on text. Thanks to Pathway, you can spot and act on trends in real-time, before
                            they burst into mainstream.</p>
                        <NuxtLink to="/developers/templates/etl/twitter"
                            class="inline items-center font-normal hover:underline text-primary-500 transition">
                            Check It
                            Out
                            <Icon name="heroicons:arrow-right-16-solid"
                                class="w-4 h-4 inline align-middle mb-1 ml-1 button-arrow" />
                        </NuxtLink>
                    </div>
                    <div>
                        <div
                            class="lg:w-[30rem] w-full h-48 bg-[#F8FAFC] rounded-lg flex items-center justify-center font-bold text-gray-200">
                            <NuxtLink to="/developers/templates/etl/twitter">
                                <NuxtImg src="/assets/content/showcases/twitter/pathway-twitter-mtva.gif"
                                    alt="Highlighted important information"
                                    class="rounded-lg object-cover w-full h-full" />
                            </NuxtLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr class="border-[#242424]" />
    <div class=" bg-[#1F1F1F] pb-14">
        <div class="max-w-7xl mx-auto md:p-14 px-4 py-14 text-white">
            <h2 id="pricing" class="text-4xl font-semibold text-center">What you need</h2>
            <p class="mt-8 text-pretty">Pathway deploys with Kubernetes, running on your cloud of choice or on premises.
                Pathway can be
                used to
                transform data streams as they enter your warehouse, data in unstructured storage (files, documents,
                blobs),
                and semi-structured JSON's.
            </p>
            <p class="mt-4 text-pretty">Pathway's high performance Rust engine performs data transformation and indexing
                in
                memory,
                which means all
                meta data and indexing information stays in memory while binary data (blobs) can stay in cold storage.
                You
                can size your container size between 1GB and 12TB+ of RAM on a single machine, going up to petabytes
                across
                multiple machines. You will need to provide the Pathway container with a cold storage location
                (S3-compatible) where it can persist its state, resuming from a checkpoint in case of machine failure or
                whenever you need to upgrade your pipeline logic.</p>
            <Calendly name="GetStarted" href="https://calendly.com/d/dj2-4jd-3tw/introductory-call-pathway"
                class="text-white bg-primary-500 p-4 rounded-lg font-bold mt-8 w-full hover:bg-transparent border border-primary-500 transition">
                Let's discuss your use case
            </Calendly>
            <!-- <Modal
                :button="{ class: 'text-white bg-primary-500 p-4 rounded-lg font-bold mt-8 w-full hover:bg-transparent border border-primary-500 transition' }"
                :modal="{ name: 'GetStarted', props: { formActive: true } }">Let's discuss your use case
            </Modal> -->
        </div>
    </div>
    <hr class="border-[#242424]" />
    <div class=" bg-[#1F1F1F] pb-14">
        <div class="max-w-7xl mx-auto lg:p-14 px-4 py-14 text-white">
            <h2 id="pricing" class="text-2xl font-semibold text-center">Power your unstructured data pipelines with
                Pathway
            </h2>
            <div
                class="flex lg:flex-wrap flex-col lg:flex-row lg:flex-nowrap gap-6 mx-auto items-center justify-center mt-8">
                <div class="lg:w-1/2 w-full grow items-center flex  flex-1">
                    <List type="success">
                        <li>Data source synchronization with live updates </li>
                        <li>Enterprise data connectors: Sharepoint, S3, Kafka, API’s, database sync</li>
                        <li>Application templates to answer user questions based on live data sources</li>
                        <li>Combining multiple data sources</li>
                    </List>
                </div>
                <div class="lg:w-1/2  w-full grow items-center flex  flex-1">
                    <List type="success">
                        <li>Alerting when answers to queries change</li>
                        <li>Machine unlearning of deleted data</li>
                        <li>Explainable AI pipelines</li>
                        <li>Event stream processing with advanced data transformations</li>
                        <li>Rapid Containerized Deployment</li>
                    </List>
                </div>
            </div>
        </div>
    </div>
    <div class="bg-[#19242F]">
        <div class="max-w-7xl mx-auto md:p-8 md:py-16 px-4 py-8 text-white">
            <span class="text-3xl font-semibold">Backed by Enterprise Security & Authentication</span>
            <div class="flex flex-col md:flex-row gap-8 justify-between items-center">
                <div class="md:w-[75%]">
                    <ul class="flex flex-col gap-4 mt-8">
                        <li class="flex gap-4 text-white font-light items-center text-xl"><img
                                src="/assets/landing/icon-check-gray.svg" alt="" class="w-8 h-8" /> <span>Host on your
                                cloud
                                or
                                on-premise</span>
                        </li>
                        <li class="flex gap-4 text-white font-light items-center text-xl"><img
                                src="/assets/landing/icon-check-gray.svg" alt="" class="w-8 h-8" /> <span>Secure by
                                Design</span>
                        </li>
                        <li class="flex gap-4 text-white font-light items-center text-xl"><img
                                src="/assets/landing/icon-check-gray.svg" alt="" class="w-8 h-8" /> <span>Granular
                                Access
                                Management</span>
                        </li>
                        <li class="flex gap-4 text-white font-light items-center text-xl"><img
                                src="/assets/landing/icon-check-gray.svg" alt="" class="w-8 h-8" />
                            <span>Compliance-Ready</span>
                        </li>
                    </ul>
                </div>
                <div class="flex items-center justify-center h-full">
                    <Modal name="GetStarted"
                        class="flex gap-2 items-center text-white font-bold whitespace-nowrap text-xl hover:!bg-transparent block w-full md:max-w-max !bg-primary-500 px-6 py-3 rounded-lg border-2 border-primary-500 !transition">
                        Let's talk
                    </Modal>
                </div>
            </div>

        </div>
    </div>
</template>

<style scoped>
.hero {
    background: #141d2d;
    background: linear-gradient(90deg, #141d2d 42%, #152033 50%, #2729b0);
}

.quote-slider {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: -1rem;
        left: 50%;
        transform: translateX(-50%);
        width: 2rem;
        height: 2rem;
        padding: 1rem;
        background-color: #21303F;
        background-image: url("/assets/landing/quote.svg");
        color: red;
        background-size: 50%;
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid #7B8088;
        border-radius: 50%;
    }
}
</style>
